import React from "react";
import { telephone } from "./mask";

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: "",
      telephone: "",
    };
    this.handlechange = this.handlechange.bind(this);
  }

  handlechange(e) {
    this.setState({ telephone: telephone(e.target.value) });
  }

  hideAlerts() {
    this.setState({ ...this.state, status: "" });
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        this.setState({ status: "SUCCESS" });
        ev.target.reset();
        this.setState({ telephone: "" });
        this.setState({ value: "" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }

  render() {
    const { status } = this.state;
    const { telephone } = this.state;
    return (
      <>
        <div className="row container-fluid m-0">
          <div className="map col-sm-12 col-md-6">
            <h2 className="text-center">LOCALIZAÇÃO</h2>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d72863.55438962579!2d-48.95690599118728!3d-16.301528879477747!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x941c08a9dd66a4de!2sBestSmart!5e0!3m2!1spt-BR!2sbr!4v1610561329667!5m2!1spt-BR!2sbr"
              title="Mapa"
              width="100%"
              height="80%"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
          <div id="contact" className="col-sm-12 col-md-6">
            <div className="row justify-content-between">
              <div className="col-sm-12">
                <h2 className="text-center">CONTATO</h2>
                <form
                  onSubmit={this.submitForm}
                  action="https://formspree.io/f/mknpqkll"
                  method="POST"
                >
                  <div className="row">
                    <div className="col-sm-12 form-group">
                      <input
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Nome"
                        type="text"
                        required
                      />
                    </div>
                    <div className="col-sm-12 form-group">
                      <input
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="E-mail"
                        type="email"
                        required
                      />
                    </div>
                    <div className="col-sm-12 form-group">
                      <input
                        className="form-control"
                        id="telephone"
                        name="telephone"
                        placeholder="Telefone"
                        type="string"
                        required
                        minLength={14}
                        maxLength={15}
                        value={telephone}
                        onChange={this.handlechange}
                      />
                    </div>
                  </div>
                  <textarea
                    className="form-control"
                    id="comments"
                    name="comments"
                    placeholder="Assunto"
                    rows="2"
                  ></textarea>
                  <br />
                  <div className="row">
                    <div className="button-form">
                      {status === "SUCCESS" ? (
                        <div
                          className="alert alert-success alert-dismissible fade show"
                          role="alert"
                          íd="success-alert"
                        >
                          <strong>Enviado!</strong> Entraremos em contato em até
                          24 horas.
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                            onClick={(ev) => this.hideAlerts()}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      ) : (
                        <button className="btn btn-secondary right">
                          Enviar
                        </button>
                      )}
                      {status === "ERROR" && (
                        <div
                          className="alert alert-danger alert-dismissible fade show"
                          role="alert"
                        >
                          <strong>Ooops!</strong> Algo deu errado. Tente
                          novamente mais tarde.
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                            onClick={(ev) => this.hideAlerts()}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
