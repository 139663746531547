import React from "react";
import axios from "axios";

import logo from "../assets/a.png";

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: "",
      email: "",
    };
  }

  hideAlerts() {
    this.setState({ ...this.state, status: "" });
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  submitForm(ev) {
    ev.preventDefault();
    axios
      .post(
        "https://api.hsforms.com/submissions/v3/integration/submit/9024736/c8d616e4-540f-4648-9de3-2f0e373e0dcb",
        {
          fields: [
            {
              name: "email",
              value: this.state.email,
            },
          ],
        }
      )
      .then(() => {
        this.setState({
          status: "SUCCESS",
        });
        ev.target.reset();
        this.setState({ email: "" });
      })
      .catch(() => {
        this.setState({
          status: "ERROR",
        });
      });
  }

  render() {
    const { status } = this.state;
    return (
      <>
        <div className="row text-center justify-content-around container-fluid py-0 px-0 bg-info m-0 ">
          <div className=" col-lg-4 py-4 col-sm-12 col-md-6">
            <img src={logo} className="img-footer" alt="Logo" />
          </div>
          <div className="py-4 col-lg-4 col-sm-12 col-md-6">
            <form onSubmit={(ev) => this.submitForm(ev)}>
              <div className="row"></div>
              <div className="form-group input-group ">
                <input
                  className="form-control "
                  id="email"
                  name="email"
                  placeholder="Digite o E-mail"
                  type="E-mail"
                  required
                  value={this.state.email}
                  onChange={(ev) => this.handleEmailChange(ev)}
                />
                <div>
                  {status === "SUCCESS" ? (
                    <div
                      className="alert alert-success alert-dismissible fade show"
                      role="alert"
                      íd="success-alert"
                    >
                      <strong>Enviado!</strong>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={(ev) => this.hideAlerts()}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : (
                    <button className="subscriber-button btn btn-secondary">
                      Se Inscrever
                    </button>
                  )}
                  {status === "ERROR" && (
                    <div
                      className="alert alert-danger alert-dismissible fade show"
                      role="alert"
                    >
                      <strong>Ooops!</strong> Algo deu errado. Tente novamente
                      mais tarde.
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={(ev) => this.hideAlerts()}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </form>
            <div className="newsletter-text">
              <p>
                Ao assinar, você concorda em receber comunicações sobre
                produtos, serviços e eventos da BestSmart. Seus dados pessoais
                serão gerenciados pela BestSmart de acordo com a Política de
                Privacidade. Você pode cancelar sua inscrição a qualquer
                momento.
              </p>
            </div>
          </div>
        </div>
        <footer className="text-center bg-info pb-2">
          <p className="m-0">@2021 BestSmart. Todos os direitos reservados. </p>
        </footer>
      </>
    );
  }
}
