import React from "react";
import CardDeck from "react-bootstrap/CardDeck";
import Card from "react-bootstrap/Card";
import Cloud_Services from "../assets/Cloud_Services.svg";
import IT_Compliance from "../assets/TI_Compliance.svg";
import Outsorcing from "../assets/Outsorcing.svg";
import Consultoria_TI from "../assets/Consultoria_de_TI.svg";
import Erp from "../assets/Softwares_de_gerenciamento.svg";
import Infraestrutura_Assessment from "../assets/Infra_Assesment.svg";
import Button from "react-bootstrap/Button";

const Services = (props) => {
  return (
    <div>
      <div id="services" className="container-fluid text-center">
        <h2>SERVIÇOS</h2>
        <br />
        <CardDeck>
          <Card className="animation-card">
            <Card.Img variant="top" src={Erp} />
            <Card.Body>
              <Card.Title>Softwares de Gerenciamento</Card.Title>
              <Card.Text>
                Sistemas de gestão personalizados ao seu nicho de mercado,
                agregando valor e praticidade na gestão do seu negócio no dia a
                dia. Soluções 100% Cloud com custo acessível e robustez
                carregando a garantia e flexibilidade que seu negócio demanda
                (Totvs Partner).
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button
                variant="secondary"
                size="sm"
                href="https://api.whatsapp.com/send?phone=556237026268&text=Olá! Gostaria de mais informações sobre os Softwares de Gestão."
                target="_blank"
              >
                Saiba mais
              </Button>{" "}
            </Card.Footer>
          </Card>
          <Card className="animation-card">
            <Card.Img variant="top" src={Consultoria_TI} />
            <Card.Body>
              <Card.Title>Consultoria de TI</Card.Title>
              <Card.Text>
                Serviços de consultoria especializada em entregar agilidade e
                redução de custos com foco no seu negócio. Atendemos
                necessidades pontuais e projetos de pequeno a grande porte com
                gestão, tecnologia e mão de obra especializada.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button
                variant="secondary"
                size="sm"
                href="https://api.whatsapp.com/send?phone=556237026268&text=Olá! Gostaria de mais informações sobre Consultoria de TI."
                target="_blank"
              >
                Saiba mais
              </Button>{" "}
            </Card.Footer>
          </Card>
          <Card className="animation-card">
            <Card.Img variant="top" src={Outsorcing} />
            <Card.Body>
              <Card.Title>Outsorcing</Card.Title>
              <Card.Text>
                Terceirize sua demanda de TI e foque 100% em seu negócio,
                levamos até você profissionais especializados, soluções e
                serviços de acordo com a sua necessidade.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button
                variant="secondary"
                size="sm"
                href="https://api.whatsapp.com/send?phone=556237026268&text=Olá! Gostaria de mais informações sobre Outsourcing."
                target="_blank"
              >
                Saiba mais
              </Button>{" "}
            </Card.Footer>
          </Card>
        </CardDeck>
        <br />
        <CardDeck>
          <Card className="animation-card">
            <Card.Img variant="top" src={Infraestrutura_Assessment} />
            <Card.Body>
              <Card.Title>Infraestrutura Assessment</Card.Title>
              <Card.Text>
                Levantamento, detalhamento, documentação de infraestrutura e
                periféricos, com soluções tecnológicas e KPIs personalizados de
                acordo com sua demanda, reduzindo custos e agregando a TI ao
                negócio.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button
                variant="secondary"
                size="sm"
                href="https://api.whatsapp.com/send?phone=556237026268&text=Olá! Gostaria de mais informações sobre Infraestrutura Assesment."
                target="_blank"
              >
                Saiba mais
              </Button>{" "}
            </Card.Footer>
          </Card>
          <Card className="animation-card">
            <Card.Img variant="top" src={IT_Compliance} />
            <Card.Body>
              <Card.Title>It Compliance</Card.Title>
              <Card.Text>
                Sua TI padronizada e estruturada em compliance com as mais
                importantes normas e processos (ISO2701, LGPD).
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button
                variant="secondary"
                size="sm"
                href="https://api.whatsapp.com/send?phone=556237026268&text=Olá! Gostaria de mais informações sobre IT Compliance."
                target="_blank"
              >
                Saiba mais
              </Button>{" "}
            </Card.Footer>
          </Card>
          <Card className="animation-card">
            <Card.Img variant="top" src={Cloud_Services} />
            <Card.Body>
              <Card.Title>Cloud Services</Card.Title>
              <Card.Text>
                Proteja seu negócio sem perder o gerenciamento e escalabilidade
                de seus serviços e dados com disponibilidade em nuvem de até
                99,99%. Serviços de E-mail, Cloud Backup, Cloud Host, Disaster
                Recovery e Soluções SaaS.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button
                variant="secondary"
                size="sm"
                href="https://api.whatsapp.com/send?phone=556237026268&text=Olá! Gostaria de mais informações sobre Cloud Services."
                target="_blank"
              >
                Saiba mais
              </Button>
              {""}
            </Card.Footer>
          </Card>
        </CardDeck>
      </div>
    </div>
  );
};

export default Services;
