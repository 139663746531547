import React, { useState, useEffect } from "react";
import logo from "../assets/a.png";
import whatsapp from "../assets/whatsapp.png";
import whatsappSm from "../assets/whatsapp-sm.png";

const Header = (props) => {
  const [header, setHeader] = useState("header");

  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      return setHeader("header");
    } else if (window.scrollY > 70) {
      return setHeader("bg-info");
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);
  return (
    <div>
      <nav className={`navbar fixed-top navbar-expand-lg  ${header}`}>
        <div className="container">
          <a className="navbar-brand" href="#banner">
            <img
              src={logo}
              width="150"
              alt="Logo"
              loading="lazy"
              className="d-inline-block align-top"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="myNavbar">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link" href="#services">
                  SERVIÇOS
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#about">
                  QUEM SOMOS
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#partners">
                  PARCEIROS
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact">
                  CONTATO
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="d-none d-lg-block">
        <a
          href="https://api.whatsapp.com/send?phone=556237026268&text=Olá!"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="whatsapp" alt="WhatsApp" src={whatsapp} />
        </a>
      </div>
      <div className="d-lg-none">
        <a
          href="https://api.whatsapp.com/send?phone=556237026268&text=Olá!"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="whatsapp-sm" alt="WhatsApp" src={whatsappSm} />
        </a>
      </div>
    </div>
  );
};

export default Header;
