import React from "react";
import Header from "./views/Header";
import Services from "./views/Services";
import Contact from "./views/Contact";
import Footer from "./views/Footer";
import Banner from "./views/Banner";
import About from "./views/About";
import Partners from "./views/Partners"

function App() {
  return (
    <div className="App">
      <Header />
      <Banner />
      <Services />
      <About />
      <Partners />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
