export const telephone = (value) => {
  let a = value;

  a = a.replace(/\D/g, "");
  a = a.replace(/(\d{2})(\d)/, "($1) $2");
  if (a.length <= 13) {
    a = a.replace(/(\d{4})(\d)/, "$1-$2");
  }
  a = a.replace(/(\d{5})(\d)/, "$1-$2");
 
  return a;
};
