import React from "react";
import Carousel from "react-bootstrap/Carousel";
import first from "../assets/banner1.jpg";
import second from "../assets/banner2.jpg";
import third from "../assets/banner3.jpg";

const Banner = (props) => {
  return (
    <Carousel id="banner">
      <Carousel.Item>
        <img className="d-block w-100" src={first} alt="First slide" />
        <Carousel.Caption className="carousel-caption-text">
          <h3>Consultoria de TI</h3>
          <p className="d-none d-lg-block">
            Serviços de consultoria especializada em entregar agilidade e
            redução de custos com foco no seu negócio. Atendemos necessidades
            pontuais e projetos de pequeno a grande porte com gestão, tecnologia
            e mão de obra especializada.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={second} alt="Second slide" />
        <Carousel.Caption className="carousel-caption-text">
          <h3>Cloud Services</h3>
          <p className="d-none d-lg-block">
            Gerencie e mantenha seus serviços disponíveis 99,99% na nuvem.
            Serviços de E-mail, Cloud Backup e Cloud Host, Software e Serviços
            As-a-Service.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={third} alt="Third slide" />
        <Carousel.Caption className="carousel-caption-text">
          <h3>Infraestrutura Assessment</h3>
          <p className="d-none d-lg-block">
            Levantamento, detalhamento, documentação de infraestrutura e
            periféricos, com soluções tecnológicas e KPIs personalizados de
            acordo com sua demanda, reduzindo custos e agregando a TI ao
            negócio.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default Banner;
