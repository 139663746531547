import React from "react";
import CardDeck from "react-bootstrap/CardDeck";
import Card from "react-bootstrap/Card";
import Microsoft from "../assets/Microsoft.png";
import Sophos from "../assets/Sophos.png";
import Panda from "../assets/Panda.png";
import Cisco from "../assets/Cisco.png";
import Totvs from "../assets/Totvs.png";
import Planet from "../assets/Planet.png";

const Partners = (props) => {
  return (
    <div>
      <div
        id="partners"
        className="container-fluid container-fluid text-center bg-grey"
      >
        <h2>Parceiros</h2>
        <br />
        <CardDeck>
          <Card className="animation-card">
            <a href="https://www.totvs.com" target="_blank" rel="noreferrer">
              <Card.Img variant="top" src={Totvs} />
            </a>
          </Card>
          <Card className="animation-card">
            <a href="https://planet.com.br" target="_blank" rel="noreferrer">
              <Card.Img variant="top" src={Planet} />
            </a>
          </Card>
          <Card className="animation-card">
            <a href="https://www.cisco.com/c/pt_br/index.html" target="_blank" rel="noreferrer">
              <Card.Img variant="top" src={Cisco} />
            </a>
          </Card>
          <Card className="animation-card">
            <a href="https://www.microsoft.com/pt-br" target="_blank" rel="noreferrer">
              <Card.Img variant="top" src={Microsoft} />
            </a>
          </Card>
          <Card className="animation-card">
            <a href="https://www.pandasecurity.com/pt/" target="_blank" rel="noreferrer">
              <Card.Img variant="top" src={Panda} />
            </a>
          </Card>
          <Card className="animation-card">
            <a href="https://www.sophos.com/en-us.aspx" target="_blank" rel="noreferrer">
              <Card.Img variant="top" src={Sophos} />
            </a>
          </Card>
        </CardDeck>
      </div>
    </div>
  );
};

export default Partners;
