import React from "react";
import company from "../assets/about.png";
import mission from "../assets/mission.png";

const About = (props) => {
  return (
    <div>
      <div id="about" className="container-fluid bg-grey">
        <div className="row">
          <div className="col-sm-8">
            <h2>Sobre a Empresa</h2>
            <h4>
              A Best Smart Tecnologia atua a mais de 6 anos no mercado de
              tecnologia e inovação trabalhando como integradora de soluções
              tecnológicas ao negócio, objetivando otimizar recursos
              pré-existentes, adequar a T.I. aos objetivos do negócio e
              implantar os princípios do melhoramento contínuo.
            </h4>
            <h4>
              Trabalhamos com as mais diversas e modernas tecnologias para
              oferecer aos nossos clientes os melhores produtos e serviços, sempre
              acompanhado de um atendimento personalizado, onde o negócio do
              cliente é o ponto focal de nossa estratégia.
            </h4>
            <h4>
              Nossa preocupação em atender bem, orgulhosamente nos proporcionou
              grandes experiências, nos permitindo atender desde pequenos a
              grandes clientes com projetos realizados em todo o território
              nacional.
            </h4>
          </div>
          <div className="col-sm-4">
            <img src={company} className="logo" alt="Logo" />
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-4">
            <img src={mission} className="logo" alt="Logo" />
          </div>
          <div className="col-sm-8">
            <h4>
              <strong>MISSÃO:</strong> Temos o compromisso de levar a tecnologia
              a cada um dos nossos clientes como uma ferramenta estratégica,
              usamos as soluções tecnológicas como inteligência competitiva para
              alcançar novos marcos.
            </h4>
            <h4>
              <strong>VISÃO:</strong> Simplificar a tecnologia para nossos
              clientes nos tornando uma empresa pioneira em transformar a TI em
              uma aliada do negócio, onde independente das soluções utilizadas o
              objetivo seja um só, a evolução contínua do negócio.
            </h4>
            <h4>
              <strong>VALORES:</strong> Sustentabilidade, honestidade,
              comprometimento, seriedade e transparência fazendo com que cada
              projeto se torne uma grande experiência aos nossos clientes.
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
